import { compareAsc, compareDesc, isValid, parseISO } from 'date-fns'
import type { SortDirection } from '@/types'

// eslint-disable-next-line ts/no-explicit-any
export const basicSorter = (valueA: any, valueB: any, direction: SortDirection = 'ASC') => {
  if (valueA > valueB) {
    return direction === 'ASC' ? 1 : -1
  }

  if (valueA < valueB) {
    return direction === 'ASC' ? -1 : 1
  }

  return 0
}

/* Should sort dates and numbers correctly */
export const sorter = (valueA: string | number, valueB: string | number, direction: SortDirection = 'ASC') => {
  if (typeof valueA === 'string' && typeof valueB === 'string') {
    const dateA = parseISO(valueA)
    const dateB = parseISO(valueB)

    if (isValid(dateA) && isValid(dateB)) {
      return direction === 'ASC' ? compareDesc(dateA, dateB) : compareAsc(dateA, dateB)
    }
  }

  return basicSorter(valueA, valueB, direction)
}

/*
 * `orderBy` can be either a name of the field in the object, or a function to extract the nested field value
 */

interface SortObjectsOptions<T extends object> {
  // eslint-disable-next-line ts/no-explicit-any
  orderBy: string | ((value: T) => any)
  direction?: SortDirection
}

export const sortObjects = <T extends object>(data: T[], { orderBy, direction = 'ASC' }: SortObjectsOptions<T>) => {
  const result = [...data]

  return result.sort((A, B) => {
    const sortFunc = typeof orderBy === 'string' ? (value: T) => value[orderBy as keyof T] : orderBy

    const valueA = sortFunc(A)
    const valueB = sortFunc(B)

    return sorter(valueA, valueB, direction)
  })
}

export const getSortState = <T extends string>(key: T, current?: T) => {
  const currentOrderBy = current?.replace('-', '')
  const currentDirection = current?.startsWith('-') ? 'desc' : 'asc'

  return currentOrderBy === key ? currentDirection : 'none'
}

export const updateSortState = <T extends string>(key: T, current?: T | `-${T}`): T | `-${T}` => {
  const state: T | `-${T}` = current === key ? `-${key}` : key

  return state
}
