import { createI18n } from 'vue-i18n'
import messages from '@/lang/messages'

const i18n = createI18n({
  legacy: false,
  globalInjection: true,
  locale: navigator.language,
  fallbackLocale: 'en-US',
  messages,
})

export default i18n
export const { t: $t, tm: $tm } = i18n.global
