import type { GraphNodeShape } from '@unovis/ts'

/* < types A > */
export interface ActiveFilterValue {
  displayName: string
  value: ActiveFilterValueTypes
}

export type ActiveFilterValueTypes = string | number | boolean

export type APIsSortValues = 'host.name' | 'endpoint_count' | 'request_count'

export type APIsOrderBy = APIsSortValues | `-${APIsSortValues}`

export interface APIsViewSettings {
  orderBy?: APIsOrderBy | undefined
  page: number
  size: number
  startDate: string
  endDate: string
  binDuration: string
}

export interface APIEndpointsViewSettings {
  page: number
  size: number
  orderBy: string
}

export interface APIVulnerabilitiesViewSettings {
  page: number
  size: number
  orderBy: string
}

export type AppEndpointsSortValues = 'host.name' | 'path_template' | 'is_first_party' | 'last_scanned_at'

export type AppEndpointsOrderBy = AppEndpointsSortValues | `-${AppEndpointsSortValues}`

export type AppAPIsSortValues = 'host.name'

export type AppAPIsOrderBy = AppAPIsSortValues | `-${AppAPIsSortValues}`

export type AppsOrderBy = AppsSortValues | `-${AppsSortValues}`

export type AppsSortValues =
  | 'name'
  | 'created_at'
  | 'vulnerabilities.active'
  | 'last_scanned_at'

export enum AppsView {
  APPS,
  DETAILS,
}

export interface AppsViewSettings {
  orderBy?: AppsOrderBy | undefined
  page: number
  size: number
}

export interface AppAPIsViewSettings {
  page: number
  size: number
  isFirstParty: boolean | undefined
  orderBy: string
}

export interface AppEndpointsViewSettings {
  page: number
  size: number
  isFirstParty: boolean | undefined
  orderBy: string
}

export interface AppScriptsViewSettings {
  page: number
  size: number
  isFirstParty: boolean | undefined
  orderBy: string
}

export interface AppVulnerabilitiesViewSettings {
  page: number
  size: number
  status?: Array<string>
  suppressed?: boolean
  firstDetectedAt?: string
  lastDetectedAt?: string
  resourceKind?: Array<string>
  issueSeverity?: Array<string>
  issueId?: Array<string>
  issueCategoryId?: Array<string>
  orderBy: string
}

export interface AvailableFeatureFlags {
  key: string
  title: string
  description?: string
}

export enum AWSError {
  AccessDenied = 'AccessDenied',
  NoSuchKey = 'NoSuchKey',
  ResourceNotFound = 'ResourceNotFound',
}

export type AuditLogSortValues = 'handler' | 'timestamp'

export type AuditLogsOrderBy = AuditLogSortValues | `-${AuditLogSortValues}`

export interface AuditLogsViewSettings {
  orderBy?: AuditLogsOrderBy | undefined
  page: number
  size: number
  search: string
  email: string
  handler: string
}

/* < types B > */

export interface BaseBarChartData {
  x: string
  y: number
  median_score: number
  color?: string
  timestamp?: string
}

export interface BaselineBarColors {
  bottom: string
  top: string
}

export interface BaselineBarData {
  x: string | number
  y: number
  index: number
}

export interface BaseLineChartDataRecord {
  index: number
  x: Date
  y: number
}

export interface Breadcrumb {
  interactive?: boolean
  title: string
  href?: string
  callback?: () => void
}

/* < types C > */

export interface ChartDataColumn {
  className?: string
  color?: string
  label: string
  key?: string
  value: number
}

/* < types D > */

export type DomainsSortValues =
  | 'is_healthy'
  | 'name'
  | 'discovered_host_count'
  | 'last_scanned_at'
  | 'enabled'
  | 'first_party'

export type DomainsOrderBy = DomainsSortValues | `-${DomainsSortValues}`

/* < types E > */

export interface EndpointsCountRequest {
  format?: string
  method?: Array<string>
  lastSeen?: string
  search?: string
  minRequestCount?: number
  hostId?: Array<string>
  isFirstParty?: boolean
  kind?: string
  port?: Array<number>
  minRequestRate?: number
}

export interface EndpointsRequest {
  size?: number
  page?: number
  orderBy?: EndpointSortValues | `-${EndpointSortValues}`
}

export type EndpointSortValues =
  | 'hostname'
  | 'path_template'
  | 'method'
  | 'format'
  | 'last_traffic_at'

export type EndpointsOrderBy = EndpointSortValues | `-${EndpointSortValues}`

export interface ErrorMessage {
  error: string
}

export interface ErrorMessageSettings {
  defaultMessage?: string
  sendNotifications?: boolean
}

/* < types F > */
export interface FilterOption {
  key: string
  queryParamKey: string
  disabled?: boolean
  name: string
  // isSearchFilter is used to determine if the filter should be used
  // when a user hits enter without specifying a filter
  isSearchFilter?: boolean
  type: 'autocomplete' |
    'multiselect-autocomplete' |
    'date' |
    'input' |
    'number' |
    'multiselect' |
    'select' |
    'dynamic-multiselect' |
    'multiselect-number-input'
  helperText?: string
  options?: FilterValueOption[]
  scrollToTop?: boolean
  // Default selected values for when the filters are initialized
  defaultSelectedValues?: string[]
  value?: ActiveFilterValue | ActiveFilterValue[]
  fetchHandlers?: {
    byId?: (options: {
      id: string
    }) => Promise<{ key: string, name: string } | undefined>
    list?: (options: {
      query: string
      page: number
    }) => Promise<true | { hasMorePages: boolean, totalResults: number }>
  }
}

export interface FilterValueOption {
  key: string
  name: string
}

export enum FilterPhases {
  PROPERTY = 'property',
  VALUE = 'value',
}

/* < types H > */
export type HistogramTickLabelType = 'date' | 'hour'

export interface Host {
  id?: string
  createdAt?: string
  updatedAt?: string
  name: string
  domainId: string
  attrs: Record<string, string>
}

export type HostsOrderBy = 'created_at' | 'updated_at' | 'expiry' | 'name'

/* < types I > */

export type Interval = '24h' | '7d' | '30d'

export enum EndpointsView {
  ENDPOINTS,
  DETAILS,
}

export interface IPFormSubmission {
  cidrRanges: string
  expiration: string
  notes: string
}

export type IPListOrderByFields = 'cidr' | 'is_expired' | 'created_at' | 'expires_at' | 'updated_at'

export type IPListOrderBy = IPListOrderByFields | `-${IPListOrderByFields}`

export type IssuesSortValues =
  | 'created_at'
  | 'updated_at'
  | 'name'
  | 'severity'

export type IssuesOrderBy = IssuesSortValues | `-${IssuesSortValues}`

/* < types L > */
export interface LogForwarder {
  id: string
  is_healthy: boolean
  account_id: string
  region: string
  name: string
  created_at: string
  last_processed_at: string
  version: string
}

/* < types M > */

export interface MenuOption {
  id?: string
  type?: 'default' | 'danger'
  iconName?: string
  href?: string
  separator?: boolean
  label: string
  newTab?: boolean
  enabled?: boolean
  subItems?: MenuOption[]
  groupId?: string
  parentGroups?: string[]
  queryParams?: { [key: string]: string }
}

/* < types N > */

export type Nullable<T> = T | null

/* < types O > */

export interface ObjectShape {
  [key: string]: unknown
}

export interface OptionsType {
  id: string
  name: string
}

export type OrganizationsOrderByFields =
  | 'name'
  | 'created_at'
  | 'updated_at'
  | 'demo_status'

export type OrganizationsOrderBy = OrganizationsOrderByFields | `-${OrganizationsOrderByFields}`

/* < types S > */

export interface SelectOption {
  id: string
  name: string
  severity?: Severity
  icon?: object
  iconClasses?: string
  value?: string
}

export type Severity = 1 | 2 | 3 | 4

export type SeverityRampType = 'standard' | 'small' | 'large'

export type SortDirection = 'DESC' | 'ASC'

export enum SourceConnectionStatus {
  CREATED = 'created',
  QUEUED = 'queued',
  STARTED = 'started',
  COMPLETED = 'completed',
  FAILED = 'failed',
}

export type StackedBarChartData =
  | Record<string, string | number>
  | {
    label: string
    timestamp?: string
  }

export enum Status {
  OPEN = 1,
  TO_FIX,
  SUPPRESSED,
}

/* < types T > */
export type TableHeaders = {
  class?: string
  id: string
  rightAligned?: boolean
  selectAllCheckbox?: boolean
  sortable?: boolean
  text?: string
}[]

export enum Theme {
  Dark = 'ghost-dark',
  Light = 'ghost-light',
}

/* < types U > */

export type UsersOrderByFields =
  | 'email'
  | 'name'
  | 'created_at'
  | 'updated_at'
  | 'last_login'
  | 'disabled'

export type UsersOrderBy = UsersOrderByFields | `-${UsersOrderByFields}`

/* < types V > */

export interface VisualizationLink {
  source: string
  target: string
  strokeColor?: string
  strokeWidth?: number
}

export interface VisualizationNode {
  id: string
  label: string
  icon: string
  shape: GraphNodeShape
  group?: string
  subGroup?: string
  size?: number
  fill?: string
  stroke?: string
  strokeWidth?: number
  labelLength?: number
}

export interface VisualizationGraph {
  nodes: VisualizationNode[]
  links: VisualizationLink[]
}

export interface VulnerabilitiesRequest {
  issueId?: string[]
  size?: number
  page?: number
  orderBy?: VulnerabilitiesSortValues | `-${VulnerabilitiesSortValues}`
}

export type VulnerabilitiesSortValues =
  | 'issue.severity'
  | 'status'
  | 'resource.kind'
  | 'issue.category.id'
  | 'issue.id'
  | 'first_detected_at'
  | 'last_detected_at'

export type VulnerabilitiesOrderBy = VulnerabilitiesSortValues | `-${VulnerabilitiesSortValues}`
