import { defineStore } from 'pinia'
import type { ToastMessageOptions } from 'primevue/toast'

const DEFAULT_LIFE = 5000

const useToastStore = defineStore('toasts', {
  state: () => ({
    toast: {} as ToastMessageOptions,
  }),

  actions: {
    /**
     * Used for setting custom toasts.
     * @param {number} notification - see ToastMessageOptions from PrimeVue
     * notification.life - if not set will default to 5000
     */
    triggerToast(notification: ToastMessageOptions) {
      this.toast = { life: DEFAULT_LIFE, ...notification }
    },

    /**
     * Uses a toast message with severity of 'success'.
     * @param {string} detail - Notification message,
     * with a default life of 5000
     */
    triggerErrorToast(detail: string) {
      this.toast = { severity: 'error', detail, life: DEFAULT_LIFE }
    },

    /**
     * Uses a toast message with severity of 'error'.
     * @param {string} detail - Notification message,
     * with a default life of 5000
     */
    triggerSuccessToast(detail: string) {
      this.toast = { severity: 'success', detail, life: DEFAULT_LIFE }
    },
  },
})

export default useToastStore
