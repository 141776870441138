<template>
  <div class="flex justify-center gap-x-8">
    <div class="inline-flex cursor-pointer items-center justify-center rounded-full p-2 text-secondary-400 hover:text-secondary-200">
      <Icon
        v-tooltip="'Close Navigation'"
        name="mdi:arrow-expand-left"
        class="cursor-pointer"
        @click="navigationStore.showSideNav = !navigationStore.showSideNav"
      />
    </div>
    <a class="inline-flex cursor-pointer items-center justify-center rounded-full p-2 text-secondary-400 hover:text-secondary-200">
      <Icon name="mdi:account-circle" @click="handleMenuClick" />
    </a>
    <a class="inline-flex cursor-pointer items-center justify-center rounded-full text-secondary-400 hover:text-secondary-200">
      <ThemeButton />
    </a>
  </div>

  <Popover ref="menu" class="rounded border border-secondary-300 dark:border-secondary-700" :pt="{ content: { class: '!p-0' } }">
    <button
      class="relative flex w-full cursor-pointer items-start overflow-hidden rounded-t-lg border-0 bg-transparent px-4 py-2 transition-colors duration-200 hover:bg-secondary-100 dark:hover:bg-secondary-800"
      @click="handleAccountClick"
    >
      <span class="inline-flex flex-col items-start">
        <span class="text-base font-bold">{{ currentUser?.name }}</span>
        <span class="text-sm">{{ currentUser?.organization.name }}</span>
      </span>
    </button>

    <button
      class="flex w-full content-center rounded-b-lg border-t border-secondary-300 px-4 py-2 text-secondary-500 hover:bg-secondary-100 hover:text-secondary-800 dark:border-secondary-700 dark:hover:bg-secondary-800 dark:hover:text-secondary-50"
      data-test="logout"
      @click="handleLogout"
    >
      <Icon name="mdi:logout" class="mr-2" />
      <div class="text-sm text-secondary-800 dark:text-secondary-50">
        {{ $t('signOut') }}
      </div>
    </button>
  </Popover>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useAuth0 } from '@auth0/auth0-vue'
import useUsersStore from '@/stores/users'
import useNavigationStore from '@/stores/navigation'

const usersStore = useUsersStore()
const navigationStore = useNavigationStore()
const router = useRouter()
const { logout } = useAuth0()
const { currentUser } = storeToRefs(usersStore)
const menu = ref()

const handleAccountClick = () => {
  router.push('/settings/account')
  menu.value.toggle()
}

const handleMenuClick = (event: Event) => {
  menu.value.toggle(event)
}

const handleLogout = () => {
  logout({ logoutParams: { returnTo: window.location.origin } })
}
</script>
