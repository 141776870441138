import { generate as generateUUID } from 'short-uuid'

// Used to generate a session token and store session context
// into session storage for later retrieval upon authentication
export function storeSessionAndGenerateKey(context: object) {
  const stateUUID = generateUUID()

  sessionStorage.setItem(stateUUID, JSON.stringify(context))

  return stateUUID
}

// Used to retrieve session context from session storage
export function retrieveSession(key: string) {
  const sessionState = sessionStorage.getItem(key)

  if (sessionState) {
    return JSON.parse(sessionState)
  }

  return undefined
}
