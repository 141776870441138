import type { ApiError } from '@ghostsecurity/api-client'
import useToastsStore from '@/stores/toasts'
import type { ErrorMessage, ErrorMessageSettings } from '@/types'
import i18n from '@/utils/i18n'

export const getMessage = (error: ApiError, detail: string = i18n.global.t('somethingWentWrong')) => {
  const { body = {} } = error

  return body.errors?.at(0)?.message || body?.error || detail
}

function useApiErrorToast(
  baseError: ApiError,
  { defaultMessage, sendNotifications = true }: ErrorMessageSettings = {},
): ErrorMessage {
  const toastsStore = useToastsStore()

  const error = getMessage(baseError, defaultMessage)

  if (sendNotifications) {
    toastsStore.triggerErrorToast(error)
  }

  return { error }
}

const useErrorToast = (detail: string) => {
  const toastsStore = useToastsStore()
  toastsStore.triggerErrorToast(detail)
}

export { useApiErrorToast, useErrorToast }
