<template>
  <Button
    v-tooltip.bottom="$t('switchToXMode', { x: selectedTheme === Theme.Light ? $t('dark') : $t('light') })"
    data-test="dark-mode-toggle-button"
    severity="secondary"
    class="!border-0 !bg-transparent p-2"
    aria-haspopup="true"
    aria-controls="overlay_menu"
    @click="() => changeTheme()"
  >
    <Icon v-if="selectedTheme === 'ghost-light'" name="mdi:white-balance-sunny" class="text-secondary-400 hover:text-secondary-200" />
    <Icon v-if="selectedTheme === 'ghost-dark'" name="mdi:weather-night" class="text-secondary-400 hover:text-secondary-200" />
  </Button>
</template>

<script setup lang="ts">
import * as Sentry from '@sentry/vue'
import useUsersStore from '@/stores/users'
import { Theme } from '@/types/index.js'

const usersStore = useUsersStore()

const changeTheme = () => {
  if (selectedTheme.value) {
    // Remove the old theme
    document.querySelector('html')?.classList.remove(selectedTheme.value)

    // Save the new theme to user store
    usersStore.theme = selectedTheme.value === 'ghost-light' ? Theme.Dark : Theme.Light
    selectedTheme.value = usersStore.theme

    // Add the new theme to the html tag
    document.querySelector('html')?.classList.add(selectedTheme.value)

    // Set Unovis dark mode theme conditionally
    if (selectedTheme.value === Theme.Dark) {
      document.querySelector('body')?.classList.add('theme-dark')
    } else {
      document.querySelector('body')?.classList.remove('theme-dark')
    }

    // If we already have a widget present, remove it from the DOM
    const feedback = document.querySelector('#sentry-feedback')
    feedback?.remove()

    // re-create feedback widget with new theme
    Sentry.feedbackIntegration({
      showBranding: false,
      colorScheme: selectedTheme.value === Theme.Dark ? 'dark' : 'light',
      triggerLabel: '',
      formTitle: 'Help us improve',
      messagePlaceholder: 'What happened? What could we do better?',
      submitButtonLabel: 'Submit Feedback',
      themeLight: {
        background: colors.secondary[50],
        outline: '',
      },
      themeDark: {
        background: colors.secondary[900],
        outline: '',
      },
    }).createWidget()
  }
}

const selectedTheme = ref(usersStore.theme)
</script>
