import defaultColors from 'tailwindcss/colors.js'

export const colors = {
  // Tailwind defaults
  'transparent': 'transparent',
  'current': 'currentColor',
  'black': defaultColors.black,
  'white': defaultColors.white,
  'amber': defaultColors.amber,
  'blue': defaultColors.blue,
  'emerald': defaultColors.emerald,
  'lime': defaultColors.lime,
  'indigo': defaultColors.indigo,
  'orange': defaultColors.orange,
  'red': defaultColors.red,
  'green': defaultColors.green,
  'sky': defaultColors.sky,
  'teal': defaultColors.teal,
  'violet': defaultColors.violet,
  'zinc': defaultColors.zinc,
  'gray': defaultColors.gray,
  'yellow': defaultColors.yellow,

  // Primary and Secondary colors
  'primary': defaultColors.indigo,
  'secondary': defaultColors.zinc,

  // Fill Color Tokens
  'fill-warning': 'var(--fill-warning)',
  'fill-success': 'var(--fill-success)',
  'fill-danger': 'var(--fill-danger)',
}

// Convert hex to RGB with optional alpha value
export function convertHexToRGB(hex: string, alpha: number = 1) {
  const hexColor = hex.replace('#', '')
  const r = Number.parseInt(hexColor.substring(0, 2), 16)
  const g = Number.parseInt(hexColor.substring(2, 4), 16)
  const b = Number.parseInt(hexColor.substring(4, 6), 16)
  return `rgb(${r} ${g} ${b} / ${alpha})`
}

export const baseLineChartColorPresets = [
  {
    name: 'blue',
    primaryColor: colors.blue[400],
    secondaryColor: colors.blue[600],
  },
  {
    name: 'light-blue',
    primaryColor: colors.blue[300],
    secondaryColor: colors.blue[400],
  },
  {
    name: 'indigo',
    primaryColor: colors.indigo[400],
    secondaryColor: colors.indigo[600],
  },
  {
    name: 'teal',
    primaryColor: colors.teal[400],
    secondaryColor: colors.teal[600],
  },
]

export const getRiskScoreSeverityKey = (severity: string) => {
  if (severity === 'low' || severity === 'medium') {
    return 'warn'
  }

  if (severity === 'high' || severity === 'critical') {
    return 'danger'
  }

  return 'primary'
}
