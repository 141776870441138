<template>
  <div class="print-layout p-16">
    <img class="logo w-full opacity-5" src="@/assets/images/ghost-logo-text.svg" alt="Ghost">

    <div class="print-header mb-12 flex justify-between gap-12">
      <div class="flex items-start gap-12">
        <slot name="header-left" />
      </div>

      <slot name="header-right" />
    </div>
    <slot />
  </div>
</template>

<style>
.logo {
  display: none;
}

@media print {
  .logo {
    display: block;
    position: fixed;
    left: 0;
    top: 0rem;
  }

  #sentry-feedback {
    display: none;
  }

  a[href]:after {
    content: none !important;
  }

  body {
    height: auto;
    overflow: visible !important;
  }

  div {
    float: none !important;
    box-sizing: content-box !important;
  }

  .hidden-print,
  .layout-header {
    display: none
  }

  .max-h-screen {
    max-height: none !important;
  }

  .overflow-y-scroll {
    overflow-y: visible !important;
  }

  .layout-wrapper,
  .layout-wrapper > div {
    @apply m-0;
    @apply h-auto;
  }

  .print-layout {
    @apply p-0;
  }

  @page {
    size: auto;
    margin: 0;
    padding: 4rem;
  }
}
</style>
