import { OpenAPI } from '@ghostsecurity/api-client'
import { useAuth0 } from '@auth0/auth0-vue'

export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig()
  const { getAccessTokenSilently } = useAuth0()

  // V2 API Configuration
  if (config.public.apiBase) {
    OpenAPI.BASE = config.public.apiBase
    OpenAPI.TOKEN = async () => getAccessTokenSilently()
  }
})
