import { useAuth0 } from '@auth0/auth0-vue'
import { retrieveSession, storeSessionAndGenerateKey } from '@/utils/auth'

export default defineNuxtRouteMiddleware(async (to) => {
  const { isAuthenticated, isLoading } = useAuth0()

  // Wait for the Auth0 client to be ready
  // This is needed for the initial full refresh page load
  while (isLoading.value) {
    await new Promise((resolve) => {
      setTimeout(resolve, 50)
    })
  }

  // If not authenticated and not on the login page, redirect to the login page
  if (!isAuthenticated.value && !to.path.includes('/login')) {
    const stateUUID = storeSessionAndGenerateKey({
      redirectURI: to.fullPath,
    })

    // Pass session token along as "s" query parameter
    // to the login page for later retrieval upon authentication
    const targetURL = `/login?s=${stateUUID}`
    return navigate(targetURL, to.path)
  }

  // If "k" query parameter is present, retrieve the session state and redirect to the original URL
  if (to.query.k) {
    const sessionState = retrieveSession(to.query.k as string)

    if (sessionState?.redirectURI) {
      return navigate(sessionState.redirectURI as string, to.path)
    }
  }

  // If authenticated and on the login page, redirect to the apps page
  // unless currently accepting a new user invitation
  if (isAuthenticated.value && to.path === '/login' && to.query.invitation === undefined) {
    return navigate('/apps', to.path)
  }

  return undefined
})

function navigate(path: string, toPath: string) {
  // Prevents infinite redirect loops
  if (toPath === path)
    return true
  return navigateTo(path)
}
