// plugins/sentry.client.ts
import * as Sentry from '@sentry/vue'
import { colors } from '@/utils/colors'
import useUsersStore from '@/stores/users'
import { Theme } from '@/types'

/*
  If you need to test Sentry in development, temporarily remove the 'isDev' guard.
  This will route all traffic to the 'development' environment in Sentry.
  Alternatively, you can run 'yarn build:preview' to test Sentry in production mode,
  as long as 'NUXT_PUBLIC_SENTRY_DSN' is also set in your environment variables.
  This will route all traffic to the 'production' environment in Sentry
*/
export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig()

  const isDev = process.env.NODE_ENV === 'development'

  if (isDev || !config.public.sentryDSN) {
    return {}
  }

  const { vueApp } = nuxtApp
  const router = useRouter()
  const usersStore = useUsersStore()
  const { theme } = storeToRefs(usersStore)

  Sentry.init({
    app: [vueApp],
    dsn: config.public.sentryDSN,
    environment: isDev ? 'development' : 'production',
    release: process.env.COMMIT_SHA,
    integrations: [Sentry.browserTracingIntegration({ router }), Sentry.feedbackIntegration({
      showBranding: false,
      colorScheme: theme.value === Theme.Dark ? 'dark' : 'light',
      triggerLabel: '',
      formTitle: 'Help us improve',
      messagePlaceholder: 'What happened? What could we do better?',
      submitButtonLabel: 'Submit Feedback',
      themeLight: {
        background: colors.secondary[50],
        outline: '',
      },
      themeDark: {
        background: colors.secondary[900],
        outline: '',
      },
    })],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    beforeSend(event, hint) {
      // Check if it is an exception, and if so, log it.
      if (event.exception) {
        console.error(`[Exeption handled by Sentry]: (${hint.originalException})`, { event, hint })
      }
      // Continue sending to Sentry
      return event
    },

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
  })

  vueApp.mixin(
    Sentry.createTracingMixins({ trackComponents: true, timeout: 2000, hooks: ['activate', 'mount', 'update'] }),
  )

  Sentry.attachErrorHandler(vueApp, {
    logErrors: true,
    attachProps: true,
    trackComponents: true,
    timeout: 2000,
    hooks: ['activate', 'mount', 'update'],
    attachErrorHandler: true,
  })

  return {
    provide: {
      sentrySetContext: Sentry.setContext,
      sentrySetUser: Sentry.setUser,
      sentrySetTag: Sentry.setTag,
      sentryAddBreadcrumb: Sentry.addBreadcrumb,
      sentryCaptureException: Sentry.captureException,
    },
  }
})
