import validate from "/usr/src/app/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45global from "/usr/src/app/middleware/auth.global.ts";
import user_45global from "/usr/src/app/middleware/user.global.ts";
import manifest_45route_45rule from "/usr/src/app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  auth_45global,
  user_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "ghost-admin": () => import("/usr/src/app/middleware/ghost-admin.ts")
}