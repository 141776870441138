<template>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
  <DynamicDialog />
  <Toast position="bottom-right" />
</template>

<script setup lang="ts">
import DynamicDialog from 'primevue/dynamicdialog'
import Toast from 'primevue/toast'
import { storeToRefs } from 'pinia'
import { useToast } from 'primevue/usetoast'
import useUsersStore from '@/stores/users'
import useToastsStore from '@/stores/toasts'
import { Theme } from '@/types'

const toastsStore = useToastsStore()

const usersStore = useUsersStore()
const toast = useToast()

const { toast: storeToast } = storeToRefs(toastsStore)
const { theme } = storeToRefs(usersStore)

const handleSetInitialTheme = () => {
  // Check on the initial mount of the app to see if the user has a theme set
  //  The default value in the store is undefined
  if (!theme.value) {
    // Check if the user's system is set to dark mode
    if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
      theme.value = Theme.Dark
    }

    // Check if the user's system is set to light mode
    if (window.matchMedia('(prefers-color-scheme: light)').matches) {
      theme.value = Theme.Light
    }
  }

  if (theme.value && [Theme.Dark, Theme.Light].includes(theme.value)) {
    document.querySelector('html')?.classList.add(theme.value)

    // Apply Unovis dark mode theme if dark mode is set
    if (theme.value === Theme.Dark) {
      document.querySelector('body')?.classList.add('theme-dark')
    }
  } else {
    // If somehow we don't have a theme set in the store or
    // it has not been set from the system preferences, set the theme to 'ghost-dark'
    theme.value = Theme.Dark
    document.querySelector('html')?.classList.add(Theme.Dark)
    // Apply Unovis dark mode theme
    document.querySelector('body')?.classList.add('theme-dark')
  }
}

onBeforeMount(() => {
  handleSetInitialTheme()
})

/**
 * This is the main watcher for all app wide notifications.
 */
watch(storeToast, () => {
  toast.add(storeToast.value)
})
</script>
