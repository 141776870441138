import { defineStore } from 'pinia'
import { DomainsService } from '@ghostsecurity/api-client'
import type { ApiError, Domain, DomainCreate } from '@ghostsecurity/api-client'
import { getSortState } from '@/utils/sort'
import type { DomainsOrderBy, ErrorMessage } from '@/types'
import i18n from '@/utils/i18n'
import useFiltersStore from '@/stores/filters'

type OrderBy = 'is_healthy' | 'name' | 'discovered_host_count' | 'last_scanned_at' | 'enabled'

interface ViewSettings {
  page?: number
  size?: number
  orderBy?: DomainsOrderBy
  name?: string
  isHealthy?: boolean
  firstParty?: boolean
}

const useDomainsStore = defineStore('domains', {
  state: () => ({
    domains: [] as Domain[],
    total: -1,
    unfilteredTotal: -1,
    pending: false,
    pendingIds: new Set(), // Set with ids of updating domains
    viewSettings: {
      page: 1,
      size: 25,
      orderBy: 'last_scanned_at' as DomainsOrderBy,
      name: '',
      isHealthy: undefined as boolean | undefined,
      firstParty: undefined as boolean | undefined,
    } as ViewSettings,
  }),

  persist: {
    pick: ['viewSettings.size'],
    storage: localStorage,
  },

  getters: {
    pageCount: state => Math.ceil(state.total / (state.viewSettings.size as number)),
    tabValue: state => (state.unfilteredTotal === -1 ? undefined : state.unfilteredTotal),
    nothingFound: state => state.total === 0 && !(state.viewSettings.name || state.pending),
    placeholderRowCount: (state) => {
      if (state.total !== -1) {
        return Math.min(state.viewSettings.size as number, state.total)
      }
      return state.viewSettings.size
    },
  },

  actions: {
    findById(resourceId: string) {
      return this.domains.find(({ id }) => id === resourceId)
    },

    checkSortState(field: OrderBy) {
      return getSortState(field, this.viewSettings.orderBy)
    },

    async fetch(sendNotifications = true): Promise<true | ErrorMessage> {
      const timeoutId = setTimeout(() => {
        this.pending = true
      }, 250)
      try {
        const filtersStore = useFiltersStore()
        const response = await DomainsService.listDomains({
          ...this.viewSettings,
          ...filtersStore.queryParamsFromActiveFilters,
        })

        this.domains = response.items
        this.total = response.total // this is a total of the filtered items

        return true
      } catch (e) {
        return useApiErrorToast(e as ApiError, {
          defaultMessage: i18n.global.t('fetchDomainsError'),
          sendNotifications,
        })
      } finally {
        clearTimeout(timeoutId)
        this.pending = false
      }
    },

    // Tabs count for monitors section, needs count for unfiltered data
    async fetchTotal(sendNotifications = true): Promise<true | ErrorMessage> {
      try {
        // 1 returns smallest possible response, we can't set 0
        const response = await DomainsService.listDomains({ size: 1 })

        this.unfilteredTotal = response.total

        return true
      } catch (e) {
        return useApiErrorToast(e as ApiError, {
          defaultMessage: i18n.global.t('fetchDomainsError'),
          sendNotifications,
        })
      }
    },

    async delete(resourceId: string, sendNotifications = true): Promise<true | ErrorMessage> {
      const domain = this.findById(resourceId) // cache item before deletion to use in notifications
      this.pending = true
      try {
        await DomainsService.deleteDomain({ id: resourceId })

        useSuccessToast(i18n.global.t('deleteDomainSuccess', { domain: domain?.name }), sendNotifications)

        return true
      } catch (e) {
        return useApiErrorToast(e as ApiError, {
          defaultMessage: i18n.global.t('deleteDomainError', { domain: domain?.name }),
          sendNotifications,
        })
      } finally {
        this.pending = false
      }
    },

    /*
      Wrapper around this.delete, this.update and this.fetchTotal to use in lists
    */
    async deleteInList(resourceId: string, sendNotifications = true) {
      const deleteRequest = await this.delete(resourceId, sendNotifications)

      if (deleteRequest !== true) {
        return deleteRequest
      }

      const updateRequest = await this.update(sendNotifications)

      if (updateRequest !== true) {
        return updateRequest
      }

      const totalRequest = await this.fetchTotal(sendNotifications)

      if (totalRequest !== true) {
        return totalRequest
      }

      return true
    },

    async update(sendNotifications = true) {
      const response = await this.fetch(sendNotifications)
      return response
    },

    async updateEnabled(resourceId: string, enabled: boolean, sendNotifications = true): Promise<true | ErrorMessage> {
      const domain = this.findById(resourceId)

      this.pendingIds.add(resourceId)

      try {
        await DomainsService.updateDomain({ id: resourceId, requestBody: { enabled, first_party: true } })

        if (domain) {
          domain.enabled = enabled // prevent blinking in UI
        }

        useSuccessToast(i18n.global.t('updateDomainSuccess', { domain: domain?.name }), sendNotifications)

        return true
      } catch (e) {
        return useApiErrorToast(e as ApiError, {
          defaultMessage: i18n.global.t('updateDomainError', { domain: domain?.name }),
          sendNotifications,
        })
      } finally {
        this.pendingIds.delete(resourceId)
      }
    },

    async updateOwnerShip({ domain, firstParty, sendNotifications = true }: { domain: Domain, firstParty: boolean, sendNotifications?: boolean }): Promise<true | ErrorMessage> {
      this.pendingIds.add(domain.id)
      try {
        if (firstParty) {
          // If a user is claiming a domain, enabled status can be set to what it currently is
          await DomainsService.updateDomain({ id: domain.id, requestBody: { enabled: domain.enabled, first_party: firstParty } })
        } else {
          // If a use is setting a domain to external/3rd party, discovery should be set to false
          const response = await DomainsService.updateDomain({ id: domain.id, requestBody: { enabled: false, first_party: firstParty } })
          // Update the domain now so the toggle will update in the UI
          if (response) {
            domain.enabled = response.enabled
          }
        }

        useSuccessToast(i18n.global.t('updateDomainSuccess', { domain: domain?.name }), sendNotifications)

        this.update()

        return true
      } catch (e) {
        return useApiErrorToast(e as ApiError, {
          defaultMessage: i18n.global.t('updateDomainError', { domain: domain?.name }),
          sendNotifications,
        })
      } finally {
        this.pendingIds.delete(domain.id)
      }
    },

    /*
      Wrapper around this.updateEnabled and this.update to use in lists
    */
    async updateEnabledInList(
      resourceId: string,
      enabled: boolean,
      sendNotifications = true,
    ): Promise<true | ErrorMessage> {
      const enabledRequest = await this.updateEnabled(resourceId, enabled, sendNotifications)

      if (enabledRequest !== true) {
        return enabledRequest
      }

      const updateRequest = await this.update(sendNotifications)

      if (updateRequest !== true) {
        return updateRequest
      }

      return true
    },

    async updateSort(orderBy: DomainsOrderBy) {
      this.viewSettings.orderBy = orderBy
      this.viewSettings.page = 1

      return this.update()
    },

    async updateIsHealthy(isHealthy?: boolean) {
      this.viewSettings.isHealthy = isHealthy
      this.viewSettings.page = 1

      return this.update()
    },

    async updateFirstParty(firstParty?: boolean) {
      this.viewSettings.firstParty = firstParty
      this.viewSettings.page = 1

      return this.update()
    },

    clearSearch() {
      this.viewSettings.name = ''
      this.viewSettings.page = 1

      return this.update()
    },

    clearAllFilters() {
      this.viewSettings.name = ''
      this.viewSettings.isHealthy = undefined
      this.viewSettings.page = 1

      return this.update()
    },

    async postDomains(domains: string[], sendNotifications = false): Promise<number> {
      try {
        const payload: DomainCreate[] = []

        domains.forEach((element) => {
          const newDomain: DomainCreate = { name: element.trim() }
          payload.push(newDomain)
        })

        const response = await DomainsService.createDomains({ requestBody: payload })

        if (sendNotifications) {
          useSuccessToast(i18n.global.t('domainAdded', response.length))
        }

        return (response as Domain[])?.length
      } catch (e) {
        console.error(e)

        if (sendNotifications) {
          useApiErrorToast(e as ApiError, {
            defaultMessage: i18n.global.t('pleaseEnterValidDomain'),
            sendNotifications,
          })
        }

        return 0
      }
    },
  },
})

export default useDomainsStore
