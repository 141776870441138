import { useAuth0 } from '@auth0/auth0-vue'
import { getCurrentScope } from '@sentry/browser'
import * as Sentry from '@sentry/vue'
import useDomainsStore from '@/stores/domains'
import useUsersStore from '@/stores/users'

export default defineNuxtRouteMiddleware(async (to, from) => {
  const auth0 = useAuth0()
  const domainsStore = useDomainsStore()
  const usersStore = useUsersStore()

  // Exit if the user isn't authenticated
  if (auth0.isAuthenticated.value === false) {
    return true
  }

  await auth0.checkSession()

  if (!usersStore.currentUser) {
    await usersStore.fetchCurrentUserStatus()
  }

  // If we're on the same path and the query params have changed, don't fire the middleware again!
  // Requires that we've fetched the user already as well
  // TODO: Switch to using pending for this
  if (from.path === to.path && !!usersStore.currentUser?.id) {
    return undefined
  }

  if (domainsStore.unfilteredTotal === -1) {
    await domainsStore.fetchTotal()
  }

  const currentUser = usersStore.currentUser

  // There was an error fetching the user status. Log out and redirect to the login page.
  if (currentUser === undefined) {
    Sentry.captureException(new Error('An error occurred while fetching user status, this user might be misconfigured.'))
    await auth0.logout({ logoutParams: { returnTo: window.location.origin } })
  }

  // After we have a valid user - configure Sentry scope with user info
  const sentryScope = getCurrentScope()

  if (currentUser) {
    sentryScope.setUser({ id: currentUser?.id, email: currentUser?.email, username: currentUser?.name })
    sentryScope.setExtra('OrgId', currentUser?.organization_id)
  }

  // If there are no configured domains, redirect the user to the domain setup page
  if (domainsStore.unfilteredTotal < 1) {
    return navigate('/setup/domains', to.path)
  }

  // Returning true skips following middlewares,
  // So to continue with request we should return undefined
  return undefined
})

function navigate(path: string, toPath: string) {
  // Prevents infinite redirect loops
  if (toPath === path)
    return true
  return navigateTo(path)
}
