import { storeToRefs } from 'pinia'
import useUsersStore from '@/stores/users'
import i18n from '@/utils/i18n'

export default defineNuxtRouteMiddleware(async (to, from) => {
  const usersStore = useUsersStore()
  const { currentUserIsGhostAdmin } = storeToRefs(usersStore)

  if (!currentUserIsGhostAdmin.value) {
    useErrorToast(i18n.global.t('youAreNotAuthorized'))

    return navigate('/', from.path)
  }

  return undefined
})

function navigate(path: string, toPath: string) {
  // Prevents infinite redirect loops
  if (toPath === path)
    return true
  return navigateTo(path)
}
