import payload_plugin_qLmFnukI99 from "/usr/src/app/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_4sVQNw7RlN from "/usr/src/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/usr/src/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/usr/src/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/usr/src/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/usr/src/app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/usr/src/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/usr/src/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/usr/src/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/usr/src/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/usr/src/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_WLsn00x1qh from "/usr/src/app/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import plugin_PSbK9A48ZQ from "/usr/src/app/node_modules/pinia-plugin-persistedstate/dist/nuxt/runtime/plugin.js";
import primevue_plugin_egKpok8Auk from "/usr/src/app/.nuxt/primevue-plugin.mjs";
import plugin_client_XxbS7tGyhp from "/usr/src/app/node_modules/@primevue/nuxt-module/dist/runtime/plugin.client.js";
import auth0_ZnwwTmFbx2 from "/usr/src/app/plugins/auth0.ts";
import coreClientPlugin_MPUufqj7Fi from "/usr/src/app/plugins/coreClientPlugin.ts";
import i18n_VfGcjrvSkj from "/usr/src/app/plugins/i18n.ts";
import primevue_7rYYRZQLyx from "/usr/src/app/plugins/primevue.ts";
import sentry_client_shVUlIjFLk from "/usr/src/app/plugins/sentry.client.ts";
export default [
  payload_plugin_qLmFnukI99,
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_WLsn00x1qh,
  plugin_PSbK9A48ZQ,
  primevue_plugin_egKpok8Auk,
  plugin_client_XxbS7tGyhp,
  auth0_ZnwwTmFbx2,
  coreClientPlugin_MPUufqj7Fi,
  i18n_VfGcjrvSkj,
  primevue_7rYYRZQLyx,
  sentry_client_shVUlIjFLk
]